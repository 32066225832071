import React from "react";
import "../Styles/AdCards.css";
import pharmacyImage from "../Assets/imgone.png";
import labTestImage from "../Assets/imgtwo.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const AdCards = () => {
    const navigate = useNavigate();
  return (
    <div className="cards-container">
      <div className="card test-card">
        <div className="text-container">
          <h2>Book Lab Test with Ganjean!</h2>
          <p>Book Now and Let us collect it up from your doorstep</p>
            <button className="pharmacy-btn"
             onClick={()=>{
                navigate("/health/book-lab-test")
              }}
            >Book Now</button>
        </div>
        <div className="card-image">
        <img src={labTestImage} alt="Pharmacy products" width={"200px"} />
        </div>
      </div>
      <div className="card pharmacy-card">
        <div className="text-container">
          <h2>Order Medicine from Ganjean!</h2>
          <p>Let us deliver the best medicine at your doorstep! Coming Soon</p>
          <button 
          onClick={()=>{
            toast.info("Coming Soon")
            return
            navigate("/health/order-medicine")
          }}
          
          className="pharmacy-btn">Order Now</button>
        </div>
        <div className="card-image">
        <img src={pharmacyImage} alt="Pharmacy products" width={"200px"} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdCards;
